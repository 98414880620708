import './Text.css';

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';
import twemoji from '@twemoji/api';

type TextElement = React.ElementRef<typeof Primitive.span>;
type PrimitiveTextProps = ComponentPropsWithoutRef<typeof Primitive.span> & BoxProps;
interface TextProps extends PrimitiveTextProps {
  variant?: 'display' | 'headline' | 'title' | 'body' | 'label';
  size?: 'large' | 'medium' | 'small';
  weight?: 'regular' | 'bold';
  color?: 'main' | 'guide' | 'sub' | 'hint' | 'note' | 'invert' | 'error';
  align?: 'inherit' | 'center' | 'start' | 'end';
  ellipsis?: boolean;
  twemojiEnabled?: boolean; // https://gist.github.com/chibicode/fe195d792270910226c928b69a468206
  lineClamp?: number;
}

export const Text = forwardRef<TextElement, TextProps>(
  (
    {
      variant = 'body',
      size = 'medium',
      weight = 'regular',
      color,
      align,
      ellipsis,
      twemojiEnabled = false,
      lineClamp,
      ...originalProps
    },
    forwardedRef,
  ) => {
    const { className, children, ...restProps } = styleBoxProps(originalProps);
    if (twemojiEnabled) {
      const content = typeof children === 'string' ? children : String(children ?? '');
      return (
        <Primitive.span
          {...restProps}
          ref={forwardedRef}
          style={{ lineClamp: lineClamp, WebkitLineClamp: lineClamp }}
          className={`text text-${variant}-${size} text-${weight} ${align ? `text-align-${align}` : ''} ${color ? `text-color-${color}` : ''} ${ellipsis ? 'text-ellipsis' : ''} ${lineClamp ? 'text-line-clamp' : ''} ${className} twemoji`}
          dangerouslySetInnerHTML={{
            __html: twemoji.parse(content, {
              folder: 'svg',
              ext: '.svg',
            }),
          }}
        />
      );
    }
    return (
      <Primitive.span
        {...restProps}
        ref={forwardedRef}
        style={{ lineClamp: lineClamp, WebkitLineClamp: lineClamp }}
        className={`text text-${variant}-${size} text-${weight} ${align ? `text-align-${align}` : ''} ${color ? `text-color-${color}` : ''} ${ellipsis ? 'text-ellipsis' : ''} ${lineClamp ? 'text-line-clamp' : ''} ${className}`}
      >
        {children}
      </Primitive.span>
    );
  },
);
