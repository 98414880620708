import { useSearchParams } from 'react-router';
import './ContinueWithGoogleButton.css';

import { FC } from 'react';
import GoogleLogo from 'src/icons/googleLogo.svg';
import { usePermission } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';

export const ContinueWithGoogleButton: FC = () => {
  const { i18n } = useI18n();
  const [searchParams] = useSearchParams();
  const { permit } = usePermission('sign_in_with_google');

  if (!permit) return;

  return (
    <form
      className="continue-with-google-button-container"
      action={`/auth/google?${searchParams.toString()}`}
      method="POST"
    >
      <button type="submit" className="continue-with-google-button">
        <img className="icon" src={GoogleLogo} alt="Google Logo" />
        {i18n.t('action.continue_with_google')}
      </button>
    </form>
  );
};
