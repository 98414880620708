import { FC, PropsWithChildren, ReactNode, useContext, useEffect, useMemo } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { FormOptionsContext } from 'src/components/control/Form';
import { FormControl } from 'src/components/control/FormControl';
import RadioGroup from 'src/components/styled-radix/RadioGroup';
import { useI18n } from 'src/lib/i18n';

// [!] this component hacks react-hook-form

export const RadioField: FC<
  PropsWithChildren<{
    name: string;
    id?: string;
    label?: ReactNode;
    items: { [value: string]: string };
    options?: RegisterOptions<FieldValues, string>;
    disabled?: boolean;
  }>
> = ({ name, id = name, label, items, options, children, disabled = false }) => {
  const { i18n } = useI18n();
  const { register, watch, setValue } = useFormContext<FieldValues>();
  const { readOnly } = useContext(FormOptionsContext);
  const initialValue = useMemo(() => watch(name), [name]);

  useEffect(() => {
    register(name, options);
  }, [name, options, register]);

  return (
    <FormControl
      id={id}
      name={name}
      required={options?.required ? true : false}
      label={label || i18n.t(`attributes.${name}`)}
    >
      <RadioGroup.Root
        id={id}
        aria-readonly={readOnly}
        defaultValue={initialValue}
        value={watch(name)}
        onValueChange={(value) => setValue(name, value, { shouldValidate: true, shouldDirty: true, shouldTouch: true })}
        disabled={disabled}
      >
        {Object.keys(items).map((value) => (
          <label key={value} htmlFor={`${id}-${value}`}>
            <RadioGroup.Item value={value} id={`${id}-${value}`}>
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            {items[value]}
          </label>
        ))}
      </RadioGroup.Root>
      {children}
      <ErrorMessage name={name} />
    </FormControl>
  );
};
