import './ReceptionForm.css';

import { FC, useMemo } from 'react';

import { Form } from 'src/components/control/Form';
import { Business, BusinessProfile } from 'src/models/v1/business';
import { onUpdateReception } from 'src/actions/business_profile';
import { Account } from 'src/models/v1/account';
import { useI18n } from 'src/lib/i18n';
import { FieldValues, useFormContext } from 'react-hook-form';
import { TextField } from 'src/components/control/TextField';
import { validateMaxLengthOption } from 'src/lib/validation';
import { AskFormHeader } from 'src/components/features/ticket/AskFormHeader';
import { FormControl } from 'src/components/control/FormControl';
import { Text } from 'src/components/primitives/Text';
import { FormButton } from 'src/components/control/FormButton';
import { FormLabel } from 'src/components/control/FormLabel';

export const ReceptionForm: FC<{
  account: Account;
  businessStatus: Business['status'];
  businessProfile: BusinessProfile;
}> = ({ account, businessProfile, businessStatus }) => {
  return (
    <Form
      className="reception-form"
      onSubmit={onUpdateReception({ businessStatus })}
      mode="all"
      defaultValues={{
        business_profile: {
          reception_message: businessProfile.reception_message,
          reception_body_annotation: businessProfile.reception_body_annotation,
        },
      }}
    >
      <ReceptionFormFields account={account} businessProfile={businessProfile} />
    </Form>
  );
};

const ReceptionFormFields: FC<{
  account: Account;
  businessProfile: BusinessProfile;
}> = ({ account, businessProfile }) => {
  const { i18n } = useI18n();
  const { watch } = useFormContext<FieldValues>();
  const watchReceptionMessage = watch('business_profile.reception_message', businessProfile.reception_message);
  const watchReceptionBodyAnnotation = watch(
    'business_profile.reception_body_annotation',
    businessProfile.reception_body_annotation,
  );
  const watchAccount = useMemo(
    () =>
      Object.assign(
        {},
        {
          ...account,
          business_profile: {
            ...account.business_profile!,
            reception_message: watchReceptionMessage,
            reception_body_annotation: watchReceptionBodyAnnotation,
          },
        },
      ),
    [account, watchReceptionMessage, watchReceptionBodyAnnotation],
  );
  return (
    <>
      <TextField
        scale="thin"
        name="business_profile.reception_message"
        options={{
          required: false,
          maxLength: validateMaxLengthOption({
            name: 'business_profile.reception_message',
            maxLength: 500,
            i18n,
          }),
        }}
      >
        <AskFormHeader
          account={watchAccount}
          placeholder={i18n.t('placeholder.business_profile.reception_message_legend')}
        />
      </TextField>
      <TextField
        scale="thin"
        name="business_profile.reception_body_annotation"
        options={{
          required: false,
          maxLength: validateMaxLengthOption({
            name: 'business_profile.reception_body_annotation',
            maxLength: 500,
            i18n,
          }),
        }}
      >
        <div className="reception-form-annotation-legend">
          <FormControl id="reception-form-annotation-legend" label="相談内容" required={true}>
            {watchAccount.business_profile.reception_body_annotation && (
              <Text color="note">{watchAccount.business_profile.reception_body_annotation}</Text>
            )}
            {!watchAccount.business_profile.reception_body_annotation && (
              <Text color="note">{i18n.t('placeholder.business_profile.reception_body_annotation_legend')}</Text>
            )}
          </FormControl>
        </div>
      </TextField>
      <FormLabel>{i18n.t('attributes.business_profile.cancellation_policy')}</FormLabel>
      <Text size="medium" color="main">
        {i18n.t('annotations.reception.cancellation_policy', {
          breadcrumb: `${i18n.t('title.setting')} > ${i18n.t('title.business_profile')}`,
        })}
      </Text>
      <FormButton>{i18n.t('action.save')}</FormButton>
    </>
  );
};
